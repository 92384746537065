<template>
  <div>
    <div class="page-header clear-filter" filter-color="blue">
      <parallax
        class="page-header-image"
        style="background-image:url('img/granpa-house.png')"
      >
      </parallax>
      <div class="main-logo-top-left">
         <a href="https://www.instagram.com/serendipiagames" >
            <img style="width:30%" src="img/SerendipiaLogoHorizontal.png" alt="" />
          </a>
      </div>
      <div class="container">
        <div class="content-center brand">
          <!--img class="n-logo" src="img/logo.png" alt="" /-->
          <h1 class="h1-seo">TEIOS JOURNEY</h1>
          <h3>ACTION-ADVENTURE INDIE GAME IN DEVELOPMENT</h3>
          <!--h1 class="h1-seo">SOUTH LAKES GAMES</h1>
          <h3>SOUTH LAKES GAMES</h3-->
          <!--div>
            <a href="https://www.instagram.com/serendipiagames" >
            <img style="width:10%" src="img/instagram-logo.png" alt="" />
            </a>   
          </div-->
        </div>
  
        <!--h1 class="h1-seo">SOUTH LAKES GAMES</h1>
        <h3>SOUTH LAKES GAMES</h3-->
               
        <!-- h6 class="category category-absolute">
          Designed by
          <a href="http://invisionapp.com/" target="_blank">
            <img src="img/invision-white-slim.png" class="invision-logo" /> </a
          >. Coded by
          <a href="https://www.creative-tim.com" target="_blank">
            <img
              src="img/creative-tim-white-slim2.png"
              class="creative-tim-logo"
            /> </a
          >.
        </h6-->
      </div>
    </div>
    <!-- div class="main">
      <div class="section section-images">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="hero-images-container">
                <img src="img/tank.png" alt="" />
              </div>
              <div class="hero-images-container-1">
                <img src="img/idle.png" alt="" />
              </div>
              <div class="hero-images-container-2">
                <img src="img/heal.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div-->
  </div>
</template>
<script>
import { Parallax } from '@/components';

export default {
  name: 'index',
  bodyClass: 'index-page',
  components: {
    Parallax
  }
};
</script>
<style></style>
